<template>
  <div
      v-click-outside="
      () => {
        searchLocationInputFocus = false;
      }
    "
  >
    <!-- Location Input -->
    <input
        type="text"
        class="text-xs md:text-sm h-10 bg-white border border-a-neutral-dim border-l-0 focus:outline-none w-full font-semibold px-2"
        placeholder="Location"
        v-model="locationQuery"
        @input="
        getInstantLocationSuggestions
      "
        @click="showKnownLocationsList()"
    />
    <!-- Location Input Ends -->
    <!-- Location Suggestions List Div -->
    <div
        :class="[
        'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
      ]"
        v-if="viewLocationSuggestionsList"
    >
      <div
          class="flex items-center justify-center w-full px-2 py-1 cursor-pointer font-semibold text-center hover:bg-a-secondary-lightest hover:text-a-secondary"
          @click="findNearMe()"
      >
        <Icon
            name="mdi:target"
            class="text-xl mx-1"
        />
        <span class="text-sm"
        >Find Near Me</span
        >
      </div>
      <div
          v-for="(
          suggestedLocation, index
        ) in searchLocationSuggestions"
          :key="index"
          class="w-full px-2 py-1 cursor-pointer font-semibold hover:bg-a-secondary-lightest hover:text-a-secondary text-sm"
          @click="
          setCurrentSearchLocation(
            suggestedLocation
          )
        "
      >
        {{
          (suggestedLocation as SearchLocation).place_name
        }}
      </div>
    </div>
    <!-- Location Suggestions List Div Ends -->
  </div>
</template>

<script setup lang="ts">
import type {SearchLocation} from "~/types";

const searchStore = useSearch();
const route = useRoute();

const locationQuery = ref("");
const searchLocationInputFocus =
    ref(true);

const searchLocationSuggestions = ref(
    []
);

const viewLocationSuggestionsList =
    computed(() => {
      return (
          searchLocationInputFocus.value &&
          searchLocationSuggestions.value.length >
          0
      );
    });

const emit = defineEmits(['searchWithNewlySetLocation'])
defineExpose({
  adjustLocation
})


async function findNearMe() {
  // this.searchLocationInputFocus = false;
  // let currentCoordinates =
  //   await this.$locationDetector.detectLocation();
  // let coordsBasedLocationResponse =
  //   await this.$baseDataApi.getLocationSuggestionsFromCoordinates(
  //     currentCoordinates[0],
  //     currentCoordinates[1]
  //   );
  // if (
  //   !coordsBasedLocationResponse.ok
  // ) {
  //   console.log(
  //     "No Results Found" +
  //       JSON.stringify(
  //         coordsBasedLocationResponse
  //       )
  //   );
  // } else {
  //   let suggestedLocations =
  //     coordsBasedLocationResponse
  //       .responseData.suggestions;
  //   if (
  //     suggestedLocations.length > 0
  //   ) {
  //     this.$store.dispatch(
  //       "classified/setCurrentSearchLocation",
  //       suggestedLocations[0]
  //     );
  //   } else {
  //     this.$store.dispatch(
  //       "BaseAlert/createAlert",
  //       {
  //         text: "Could not detect your location. Showing results using default location.",
  //         type: "Error",
  //       }
  //     );
  //   }
  //   this.$emit(
  //     "searchFromLocation"
  //   );
  // }
  searchLocationInputFocus.value = false;
  let currentCoordinates = await useNuxtApp().detectUserLocation();
  let coordsBasedLocationResponse =
      await useNuxtApp().getLocationSuggestionsFromCoordinates(
          currentCoordinates[0],
          currentCoordinates[1]
      );
  if(coordsBasedLocationResponse){
    let suggestedLocations =
        coordsBasedLocationResponse.suggestions;
    if (suggestedLocations.length > 0) {
      searchStore.setCurrentSearchLocation(suggestedLocations[0])
    }
    emit('searchFromLocation')
  }
}

function showKnownLocationsList() {
  searchLocationInputFocus.value = true;
  searchLocationSuggestions.value =
      searchStore.default_well_known_locations;
}

async function getInstantLocationSuggestions() {
  searchLocationInputFocus.value = true;
  if (locationQuery.value != "") {
    searchLocationSuggestions.value =
        await useLocationDataManager().fetchLocationSuggestionsByQuery(locationQuery.value)
  }
}

function setCurrentSearchLocation(
    location
) {
  locationQuery.value =
      location.place_name;
  searchStore.isSearching();
  searchStore.setCurrentSearchLocation(
      location
  );
  searchLocationInputFocus.value = false;
  emit("searchWithNewlySetLocation");
}

async function adjustLocation() {
  searchLocationInputFocus.value = false;
  if (
      locationQuery.value !=
      (searchStore
          .current_search_location as SearchLocation)
          .place_name
  ) {
    let locationInstantSearchResults =
        await useLocationDataManager().fetchLocationSuggestionsByQuery(locationQuery.value);
    if(locationInstantSearchResults) {
      setCurrentSearchLocation(
          locationInstantSearchResults[0]
      );
    }
  }
}

watch(locationQuery, () => {
  if (locationQuery.value == "") {
    searchLocationInputFocus.value = false;
  }
});

onMounted(() => {
  locationQuery.value =
      route.query.l != null
          ? route.query.l as string
          : (searchStore.current_search_location as SearchLocation).place_name;
});
</script>

<style></style>
